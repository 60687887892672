// This type of composable allows dynamic import of images
// For example in case of a dynamic img src in a v-for 

export default function useAssets() {
    // Different folder sources can be listed below
    const pngs = import.meta.globEager('../assets/images/*.png');
    const webps = import.meta.globEager('../assets/images/*.webp');

    // In the return statement, list all the assets subject to dynamic importing through the app
    return {
      clive_logo: webps['../assets/images/clive_logo.webp'].default,
      colorado_logo: webps['../assets/images/colorado_logo.webp'].default,
      consumerLive: webps['../assets/images/ConsumerLive.webp'].default,
      logo_acadomia: webps['../assets/images/logo_acadomia.webp'].default,
      logo_axa: webps['../assets/images/logo_axa.webp'].default,
      logo_bmw: webps['../assets/images/logo_bmw.webp'].default,
      logo_botanic: webps['../assets/images/logo_botanic.webp'].default,
      logo_but: webps['../assets/images/logo_but.webp'].default,
      logo_fnac_darty: webps['../assets/images/logo_fnac_darty.webp'].default,
      logo_ford: webps['../assets/images/logo_ford.webp'].default,
      logo_anah: webps['../assets/images/logo_anah.webp'].default,
      logo_bnp_paribas: webps['../assets/images/logo_bnp_paribas.webp'].default,
      logo_croquetteland: webps['../assets/images/logo_croquetteland.webp'].default,
      logo_galeries_lafayette: webps['../assets/images/logo_galeries_lafayette.webp'].default,
      logo_iberdrola: webps['../assets/images/logo_iberdrola.webp'].default,
      logo_la_poste: webps['../assets/images/logo_la_poste.webp'].default,
      logo_mobilize: webps['../assets/images/logo_mobilize.webp'].default,
      logo_nespresso: webps['../assets/images/logo_nespresso.webp'].default,
      logo_nissan: webps['../assets/images/logo_nissan.webp'].default,
      logo_se_loger: webps['../assets/images/logo_se_loger.webp'].default,
      logo_shiva: webps['../assets/images/logo_shiva.webp'].default,
      logo_yves_rocher: webps['../assets/images/logo_yves_rocher.webp'].default,
      logo_zodio: webps['../assets/images/logo_zodio.webp'].default,
      logo_edf: webps['../assets/images/logo_edf.webp'].default,
      logo_nexi: webps['../assets/images/logo_nexi.webp'].default,
      logo_lvmh: webps['../assets/images/logo_lvmh.webp'].default,
      logo_align: webps['../assets/images/logo_align.webp'].default,
      logo_arcelor_mittal: webps['../assets/images/logo_arcelor_mittal.webp'].default,
      logo_nouvelles_galeries: webps['../assets/images/logo_nouvelles_galeries.webp'].default,
      logo_delubac: webps['../assets/images/logo_delubac.webp'].default,
      logo_csa: webps['../assets/images/logo_csa.webp'].default,

      logo_google: webps['../assets/images/logo_google.webp'].default,
      logo_trustpilot: webps['../assets/images/logo_trustpilot.webp'].default,
      logo_vocalcom: webps['../assets/images/logo_vocalcom.webp'].default,
      logo_zendesk: webps['../assets/images/logo_zendesk.webp'].default,
      logo_aws: webps['../assets/images/logo_aws.webp'].default,
      logo_salesforce: webps['../assets/images/logo_salesforce.webp'].default,
      logo_eptica: webps['../assets/images/logo_eptica.webp'].default,
      logo_owncloud: webps['../assets/images/logo_owncloud.webp'].default,
      logo_meta: webps['../assets/images/logo_meta.webp'].default,
      logo_miraki: webps['../assets/images/logo_miraki.webp'].default,
      logo_slack: webps['../assets/images/logo_slack.webp'].default,
      icon_chart: webps['../assets/images/icon_chart.webp'].default,
      icon_headphones: webps['../assets/images/icon_headphones.webp'].default,
      icon_email: webps['../assets/images/icon_email.webp'].default,

      consumerlive_og: pngs['../assets/images/consumer-live-og.png'].default,
    }; 
  }