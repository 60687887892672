import { defineStore } from 'pinia';
import utils from "@/utils";
// TODO: add translations for pages meta tags

const age = ref(utils.calculateAgeInYears(1,1,2003)); // DATE TBC
const coloradoBirthDate = ref(new Date("1/1/2003")); // DATE TBC

export const useLanguageStore = defineStore('language', {
    state : () => {
        return {
            language: 'fr', // this is reactive,
            languageOptions: [
                {
                    label: "Français",
                    abbreviation: "fr"
                },
                {
                    label: "English",
                    abbreviation: "en"
                },
            ],
            cookieconsent: {
                title: {
                    fr: "Nous utilisons des cookies",
                    en: "We use cookies"
                },
                content: {
                    fr:"Nous utilisons des cookies et d'autres technologies de suivi pour améliorer votre expérience de navigation, pour analyser le trafic de notre site et pour comprendre la provenance de nos visiteurs.",
                    en:"We use cookies and other tracking technologies to improve your browsing experience, to analyze our site traffic, and to understand where our visitors come from."
                },
                buttons: {
                    accept: {
                        fr: "J'accepte",
                        en: "I accept"
                    },
                    reject: {
                        fr: "Je refuse",
                        en: "I refuse"
                    },
                    preferences: {
                        fr: "Changer mes préférences",
                        en: "Change my preferences"
                    }
                }
            },
            page_404: {
                h1: {
                    fr: "Page non trouvée",
                    en: "Page not found",
                },
                p: {
                    fr: "La page que vous tentez d'afficher n'existe pas ou une autre erreur s'est produite.",
                    en: "We are sorryn the page you requested could not be found."
                },
                a: {
                    fr: "Page d'accueil",
                    en: "Home page"
                }
            },
            skipNavigation: {
                fr: "Aller au contenu",
                en: "Skip navigation"
            },
            // META TAGS TBD
            meta_tags: {
                index: {
                    description: {
                        fr:"Plateforme de Feedback Management avec accompagnement personnalisé pour optimiser la gestion des retours clients, réengager les insatisfaits et les fidéliser.",
                        en: "Feedback Management platform with personalized support to optimize customer feedback management, re-engage dissatisfied customers and build loyalty.",
                    },
                    title: {
                        fr:"ConsumerLive® - Pilotez efficacement votre expérience client",
                        en:"ConsumerLive® - Efficiently manage your customer experience",
                    }
                },
                about: {
                    description: {
                        fr: "Découvrez notre entreprise et ses valeurs",
                        en: "Discover our company and our values"
                    },
                    title: {
                        fr: "A propos de ConsumerLive®",
                        en: "About ConsumerLive®"
                    }
                },
                contact: {
                    description: {
                        fr: "Demandez une démonstration de notre plateforme de feedback management ConsumerLive® et découvrez comment améliorer votre expérience client.",
                        en: "Request a demo of our ConsumerLive® feedback management platform and learn how to improve your customer experience."
                    },
                    title: {
                        fr: "ConsumerLive® - Demande de contact",
                        en: "ConsumerLive® - Contact request"
                    }
                },
                demo: {
                    description: {
                        fr: "", // TBD
                        en: "" // TBD
                    },
                    title: {
                        fr: "Programmer une démo",
                        en: "Schedule a demo"
                    }
                },
                legal: {
                    description: {
                        fr: "Découvrez toutes les informations de notre entreprise : Siège social, mentions légales, hébergement, moyen de contact...",
                        en: "Discover all the information of our company: Head office, legal notices, accommodation, contact info..."
                    },
                    title: {
                        fr: "ConsumerLive® - Mentions légales",
                        en: "ConsumerLive® - Legal Notice"
                    }
                },
                privacy: {
                    description: {
                        fr: "Découvrez toutes les informations concernant notre politique de confidentialité.",
                        en: "Discover all the information about our privacy policy."
                    },
                    title: {
                        fr: "ConsumerLive® - Règles de confidentialité",
                        en: "ConsumerLive® - Privacy Policy"
                    }
                }
            },
            translations: {
                abbreviations: {
                    day: {
                        fr: "j",
                        en: "d"
                    }
                },
                nav: {
                    index: {
                        fr: `Page d'accueil`,
                        en: 'Home page'
                    },
                    plans:  {
                        fr: 'Plans',
                        en: 'Plans'
                    },
                    about: {
                        fr: 'A propos',
                        en: 'About'
                    },
                    contact: {
                        fr: 'Demandez une démo',
                        en: 'Schedule a demo'
                    }
                },
                footer: {
                    caption: {
                        fr: "ConsumerLive®, Colorado groupe, tous droits réservés",
                        en: "ConsumerLive®, Colorado groupe, all rights reserved"
                    },
                    links: {
                        legal: {
                            fr: "Mentions légales",
                            en: "Legal notice"
                        },
                        confidentiality: {
                            fr: "Règles de confidentialité",
                            en: "Privacy policy"
                        }
                    }
                },
                charts: {
                    actionPlan: {
                        quadrantLabels: {
                            tl: {
                                fr: "Améliorer",
                                en: "Improve"
                            },
                            tr: {
                                fr: "Capitaliser",
                                en: "Capitalize"
                            },
                            br: {
                                fr: "Maintenir",
                                en: "Maintain"
                            },
                            bl: {
                                fr: "Surveiller",
                                en: "Monitor"
                            }
                        },
                        actions: {
                            thead: {
                                th1: {
                                    fr: "Point d'observation",
                                    en: "Observation point"
                                },
                                th2: {
                                    fr: "Action principale",
                                    en: "Main action"
                                }
                            },
                            action1: {
                                observation: {
                                    fr: "Temps d'attente en caisse",
                                    en: "Checkout waiting time"
                                },
                                action: {
                                    fr: "Mise en place de caisses automatiques",
                                    en: "Installation of automatic cash registers"
                                }
                            },
                            action2: {
                                observation: {
                                    fr: "Se repérer dans le magasin",
                                    en: "Find your way around in the store"
                                },
                                action: {
                                    fr: "A COMPLETER",
                                    en: "TO BE COMPLETED"
                                }
                            }
                        }
                    },
                    alertPanel: {
                        targetName: {
                            fr: "OBJECTIF:",
                            en: "TARGET:"
                        },
                        targets: {
                            alertsVolume: {
                                fr: "Volume d'alertes",
                                en: "Volume of alerts"
                            },
                            recontactRate: {
                                fr: "Taux de recontact",
                                en: "Recontact rate"
                            },
                            treatmentDelay: {
                                fr: "Délai de traitement",
                                en: "Treatment delay"
                            },
                            overdueAlerts: {
                                fr: "Alertes en retard",
                                en: "Overdue alerts"
                            }
                        },
                        table: {
                            rows:[
                                {
                                    type: {
                                        fr: "Avis",
                                        en: "Review"
                                    },
                                    source: {
                                        fr: "Google",
                                        en: "Google"
                                    }
                                },
                                {
                                    type: {
                                        fr: "Satisfait - SAV",
                                        en: "Satisfied - AS"
                                    },
                                    source: {
                                        fr: "Questionnaire SAV",
                                        en: "AS questionnaire"
                                    }
                                },
                                {
                                    type: {
                                        fr: "Insatisfait magasin",
                                        en: "Dissatisfied"
                                    },
                                    source: {
                                        fr: "Transfert mag Paris",
                                        en: "Paris shop transfer"
                                    }
                                },
                                {
                                    type: {
                                        fr: "Visite magasin",
                                        en: "Shop visit"
                                    },
                                    source: {
                                        fr: "Transfert mag Lyon",
                                        en: "Lyon shop transfer"
                                    }
                                }
                            ],
                            title: {
                                fr: "Alertes",
                                en: "Alerts"
                            },
                            tabs: {
                                new: {
                                    fr: "Nouveaux",
                                    en: "New"
                                },
                                inProgress: {
                                    fr: "En cours",
                                    en: "In progress"
                                },
                                processed: {
                                    fr: "Traités",
                                    en: "Processed"
                                },
                                closed: {
                                    fr: "Fermé",
                                    en: "Closed"
                                }
                            },
                            thead: {
                                alertType: {
                                    fr: "Type d'alerte",
                                    en: "Alert type"
                                },
                                source: {
                                    fr: "Source",
                                    en: "Source"
                                },
                                client: {
                                    fr: "Client",
                                    en: "Customer"
                                },
                                score: {
                                    fr: "Note",
                                    en: "Score"
                                },
                                onAlertSince: {
                                    fr: "En alerte depuis",
                                    en: "On alert since"
                                }
                            }
                        }
                    },
                    customerCard: {
                        tag: {
                            fr: "Nouveaux",
                            en: "New"
                        },
                        alertLabel: {
                            fr: "Est en alerte depuis 8 jours",
                            en: "On alert for 8 days"
                        },
                        button: {
                            fr: "Historique client",
                            en: "Customer history"
                        }
                    },
                    miniBar: {
                        title: {
                            fr: "Top topics",
                            en: "Top topics"
                        },
                        labels: {
                            one: {
                                fr: "Conseiller",
                                en: "Advisor"
                            },
                            two: {
                                fr: "Gestion de l'appel",
                                en: "Handling of the call"
                            },
                            three: {
                                fr: "Traitement de la demande",
                                en: "Processing the request"
                            }
                        }
                    },
                    npsEllipse: {
                        title: {
                            fr: "NPS Score",
                            en: "NPS Score"
                        }
                    },
                    npsEvolution: {
                        title: {
                            fr: "NPS Evolution",
                            en: "NPS Evolution"
                        }
                    },
                    rootbar: {
                        title: {
                            fr: "Analyse sémantique",
                            en: "Semantic analysis"
                        },
                        topics: [
                            {
                                fr: "Livraison",
                                en: "Delivery"
                            },
                            {
                                fr: "Temps d'attente",
                                en: "Waiting time"
                            },
                            {
                                fr: "Service client",
                                en: "Customer Service"
                            }
                        ]
                    },
                    starRating: {
                        title: {
                            fr: "Avis Google",
                            en: "Google reviews"
                        }
                    },
                    tonalityDonut: {
                        title: {
                            fr: "Tonalité des verbatims",
                            en: "Verbatim tonalities"
                        },
                        legend: {
                            positive: {
                                fr: "Positif",
                                en: "Positive"
                            },
                            neutral: {
                                fr: "Neutre",
                                en: "Neutral"
                            },
                            negative: {
                                fr: "Négatif",
                                en: "Negative"
                            }
                        }
                    },
                },
                // IMPORTANT: structure must reflect the semantic HTML architecture of the pages
                index: { // this is a page
                    h1: { // this is a DOM element
                        fr: `<span class="black-important">La solution performante et flexible pour piloter la‎ </span><span>satisfaction</span> <span class="black-important">de vos clients.</span>`,
                        en: '<span class="black-important">The most efficient solution to manage customer</span> <span>satisfaction</span>'
                    },
                    h1_p: {
                        fr: 'Avec ConsumerLive®, collectez les avis de vos clients, enrichissez vos CRM, mesurez votre NPS, identifiez les détracteurs et réengagez vos clients simplement et rapidement.',
                        en: `With ConsumerLive®, collect your customer feedbacks, enrich your CRMs, measure your NPS, identify detractors and re-engage your customers simply and quickly.`
                    },
                    buttons: {
                        discover_consumerLive: {
                            fr: 'Découvrez ConsumerLive®',
                            en: 'Discover ConsumerLive®'
                        },
                        schedule_demo: {
                            fr: "Demandez une démo",
                            en: "Schedule a demo"
                        }
                    },
                    h2_steps: {
                        fr: "4 étapes pour mobiliser vos équipes et développer la satisfaction client",
                        en: "4 steps to mobilize your teams and develop customer satisfaction"
                    },
                    sections_steps: [
                        {
                            h3: {
                                fr: "Collectez et intégrez les feedbacks clients",
                                en: "Collect & integrate internal and external customer data"
                            },
                            article: {
                                fr: `<p>Recueillez les avis de vos clients grâce à de multiples modes de collecte:  questionnaire en ligne, SMS, QR code, flyers, et même sur votre site web.<br>Intégrez d’autres sources de données : avis clients, des études adhoc, des analyses d’appels téléphoniques, analyse de réseaux sociaux, ticket réclamation …etc</p>`,
                                en: `<p>Collect customer feedback thanks to multiple modes of collection: online questionnaires, SMS, QR code, flyers, and even on your website.<br>Integrate other sources of data: customer reviews, ad hoc studies, phone call analysis, social network analysis, complaint tickets, etc.</p>`
                            },
                            highlights: [
                                {
                                    header: {
                                        fr: "Questionnaires NPS",
                                        en: "NPS questionnaires"
                                    },
                                    paragraph: {
                                        fr: "Créez des questionnaires pour chacun de vos parcours et automatisez-les.",
                                        en: "Create questionnaires for each of your customer journeys and automate them."
                                    }
                                },
                                {
                                    header: {
                                        fr: "Intégration multi-sources",
                                        en: "Multi-source integration"
                                    },
                                    paragraph: {
                                        fr: "Nous pouvons intégrer toutes vos sources de feedback à analyser.",
                                        en: "We can integrate and analyze all your sources of feedback"
                                    }
                                }
                            ],
                            button: {
                                // adding text will show the button on the page
                                fr: "",
                                en: ""
                            }
                        },
                        {
                            h3: {
                                fr: "Réengagez vos clients et vos prospects",
                                en: "Re-engage your customers and prospects"
                            },
                            article: {
                                fr: `<p>ConsumerLive® vous aide à comprendre les raisons de la perte de vos clients et à les réengager grâce à des actions ciblées via différents canaux. De plus, des alertes automatiques vous permettent de réagir rapidement pour améliorer votre taux de fidélisation.</p>`,
                                en: `<p>ConsumerLive® helps you understand the reasons for losing your customers and re-engage them through targeted actions through different channels. In addition, automatic alerts allow you to react quickly to improve your retention rate.</p>`
                            },
                            highlights: [
                                {
                                    header: {
                                        fr: "Réponses automatisées",
                                        en: "Automated answers"
                                    },
                                    paragraph: {
                                        fr: "Créez des templates de réponses automatisés (par email) pour vos alertes.",
                                        en: "Create automated response templates (email) for your alerts."
                                    }
                                },
                                {
                                    header: {
                                        fr: "Gamification",
                                        en: "Gamification"
                                    },
                                    paragraph: {
                                        fr: "Animez votre réseau en définissant des objectifs de réengagement.",
                                        en: "Animate your network by setting re-engagement goals."
                                    }
                                }
                            ],
                            button: {
                                // adding text will show the button on the page
                                fr: "",
                                en: ""
                            }
                        },
                        {
                            h3: {
                                fr: "Analysez vos feedbacks clients",
                                en: "Analyze customer feedback"
                            },
                            article: {
                                fr: `<p>Avec ConsumerLive®, suivez l'évolution de vos indicateurs clés tels que le NPS, le CSAT, le CES, ou les verbatim sur tous les moments de vérité et confrontez ces chiffres avec vos objectifs.<br><br>Utilisez des tableaux de bord personnalisés pour suivre vos KPI et motiver vos équipes.</p>`,
                                en: `<p>With ConsumerLive®, follow the evolution of your key indicators such as NPS, CSAT, CES, or the verbatim on all the moments of truth and compare results with your targets.<br><br>Use dashboards customized to track your KPIs and motivate your teams.</p>`
                            },
                            highlights: [
                                {
                                    header: {
                                        fr: "Création de dashboard",
                                        en: "Dashboard creation"
                                    },
                                    paragraph: {
                                        fr: "Créez vos propres tableaux de bord avec toutes les données disponibles dans l’outil.",
                                        en: "Create your own dashboards from all the available data."
                                    }
                                },
                                {
                                    header: {
                                        fr: "Analyse sémantique",
                                        en: "Semantic analysis"
                                    },
                                    paragraph: {
                                        fr: "Analysez plus en profondeur les feedbacks ou les échanges (voix et texte) avec vos clients.",
                                        en: "Analyze more in-depth feedback or interactions (speech and text) with your customers."
                                    }
                                }
                            ],
                            button: {
                                // adding text will show the button on the page
                                fr: "",
                                en: ""
                            }
                        },
                        {
                            h3: {
                                fr: "Priorisez vos plans d'actions",
                                en: "Prioritize your action plans"
                            },
                            article: {
                                fr: `<p>ConsumerLive® vous offre la possibilité de comprendre en profondeur les besoins et les frustrations de vos clients grâce à nos outils d’analyses avancées.<br><br>En identifiant les points de friction dans les parcours d'achat ou les points à capitaliser, définissez des plans d’actions avec vos équipes pour booster votre NPS et réduire votre taux d'attrition.</p>`,
                                en: `<p>ConsumerLive® offers you the possibility to understand in depth the needs and frustrations of your customers thanks to our advanced analysis tools.<br><br>By identifying the points of friction in the buying journeys or the points to capitalize on, define action plans with your teams to boost your NPS and reduce attrition rate.</p>`
                            },
                            highlights: [
                                {
                                    header: {
                                        fr: "Matrice de priorisation",
                                        en: "Prioritization matrix"
                                    },
                                    paragraph: {
                                        fr: "Visualisez la performance de chacun de vos points d’observation sur une matrice.",
                                        en: "Visualize the performance of each of your observation points on a matrix."
                                    }
                                },
                                {
                                    header: {
                                        fr: "Plan d'action",
                                        en: "Action plan"
                                    },
                                    paragraph: {
                                        fr: "Définissez un plan d’action pour améliorer ou capitaliser chaque point d’observation ",
                                        en: "Define action plans to improve your customer satisfaction for each datapoint."
                                    }
                                }
                            ],
                            button: {
                                // adding text will show the button on the page
                                fr: "",
                                en: ""
                            }
                        },
                    ],
                    section_steps_additional_option_h4: {
                        fr: "Option analyse sémantique & Speech analytics",
                        en: "Semantic & Speech analytics option"
                    },
                    h2_trust: {
                        fr: "Ils nous font confiance",
                        en: "They trust us"
                    },
                    h2_plans: {
                        fr: "Plans",
                        en: "Plans"
                    },
                    section_plans: {
                        cards: {
                            // TBD ! this is just one option to test datastructure
                            basic: {
                                name: {
                                    fr: "Basic",
                                    en: "Basic"
                                },
                                options: {
                                    deliverability: {
                                        fr: "Délivrabilité",
                                        en: "Deliverability"
                                    },
                                    profiling: {
                                        fr: "Profiling",
                                        en: "Profiling"
                                    },
                                    kpi: {
                                        fr: "KPI",
                                        en: "KPI"
                                    },
                                    dataMapping: {
                                        fr: "Mapping des données",
                                        en: "Data mapping"
                                    },
                                },
                                users: {
                                    name: {
                                        fr: "Users",
                                        en: "Users"
                                    },
                                    value: {
                                        fr: "100 max",
                                        en: "100 max"
                                    }
                                },
                                emailNumbers: {
                                    name: {
                                        fr: "Nombre email /<wbr> moment de vérité",
                                        en: "Number of emails /<wbr> moments of truth"
                                    },
                                    value: {
                                        fr: "20k / an",
                                        en: "20k / year"
                                    }
                                },
                                fairUse: {
                                    name: {
                                        fr: "Fair-use*",
                                        en: "Fair-use*"
                                    },
                                    value: {
                                        fr: "10Go",
                                        en: "10Go"
                                    }
                                }                
                            },
                            power: {
                                name: {
                                    fr: "Power",
                                    en: "Power"
                                },
                                options: {
                                    previous: {
                                        fr: "Toutes les options de Basic",
                                        en: "All Basic options"
                                    },
                                    reengagementModule: {
                                        fr: "Module de réengagement",
                                        en: "Re-engagement module"
                                    },
                                },
                                users: {
                                    name: {
                                        fr: "Users",
                                        en: "Users"
                                    },
                                    value: {
                                        fr: "200 max",
                                        en: "200 max"
                                    }
                                },
                                emailNumbers: {
                                    name: {
                                        fr: "Nombre email /<wbr> moment de vérité",
                                        en: "Number of emails /<wbr> moments of truth"
                                    },
                                    value: {
                                        fr: "50k / an",
                                        en: "50k / year"
                                    }
                                },
                                fairUse: {
                                    name: {
                                        fr: "Fair-use*",
                                        en: "Fair-use*"
                                    },
                                    value: {
                                        fr: "25Go",
                                        en: "25Go"
                                    }
                                }                
                            },
                            boost: {
                                name: {
                                    fr: "Boost",
                                    en: "Boost"
                                },
                                options: {
                                    previous: {
                                        fr: "Toutes les options de<wbr> Basic & Power",
                                        en: "All Basic & Power options"
                                    },
                                    cockpit: {
                                        fr: "Cockpit",
                                        en: "Cockpit"
                                    },
                                    decisionTreeAndActionPlan: {
                                        fr: "Arbre de décision &<wbr> Plans d'action",
                                        en: "Decision tree & Action plans"
                                    }
                                },
                                users: {
                                    name: {
                                        fr: "Users",
                                        en: "Users"
                                    },
                                    value: {
                                        fr: "Illimité",
                                        en: "Unlimited"
                                    }
                                },
                                emailNumbers: {
                                    name: {
                                        fr: "Nombre email /<wbr> moment de vérité",
                                        en: "Number of emails /<wbr> moments of truth"
                                    },
                                    value: {
                                        fr: "2M / an",
                                        en: "2M / year"
                                    }
                                },
                                fairUse: {
                                    name: {
                                        fr: "Fair-use*",
                                        en: "Fair-use*"
                                    },
                                    value: {
                                        fr: "40Go",
                                        en: "40Go"
                                    }
                                }                
                            }
                        }
                    },
                    h2_questions: {
                        fr: "Nous répondons à vos questions",
                        en: "We answer your questions"
                    },
                    section_questions: {
                        accordeons: [
                            {
                                answer: {
                                    fr: `<p>
                                    La plateforme ConsumerLive® peut adresser toutes les langues. Actuellement nous avons des langues européennes, sud américaine, arabe et asiatique. Le Multilingue existe à 2 niveaux :<br><br>
                                    <ul>
                                        <li>- Pour s'adresser aux clients, prospects ou collaborateurs dans leurs langues dans le questionnaire (par email, sms…)</li><li>- Pour visualiser les résultats sur la plateforme</li></ul>
                                    </p>`,
                                    en: `<p>
                                    The ConsumerLive® platform can address all languages. European, South American, Arabic and Asian languages are currently supported on the following levels:<br><br>
                                    <ul>
                                        <li>- To address customers, prospects or employees in their language in the questionnaire (by email, sms, etc.)</li><li>- To view the results on the platform</li></ul>
                                    </p>`
                                },
                                question: {
                                    fr: "Votre solution est-elle multilingue ?",
                                    en: "Is your solution multilingual ?"
                                }
                            },
                            {
                                answer: {
                                    fr: `<p>La plateforme ConsumerLive® permet d'analyser tout type de commentaires que ce soit de la voix (avec retranscription de l'enregistrement), ou du texte.</p><p>Toutes les sources sont analysées et surtout centralisées, qu'il s'agisse de verbatim des réseaux sociaux, des avis clients, des études ad hoc, des formulaires web, etc.</p><p>Les thématiques avec tonalité (positive, négative ou neutre) ainsi que l'évolution mensuelle permettent de mieux comprendre et comparer les feedback clients. N'hésitez pas à demander une démo !</p>`,
                                    en: `<p>The ConsumerLive® platform makes it possible to analyze all types of feedbacks, whether voice (with transcription of the recording), or text.</p><p>All sources are analyzed and centralized: social networks verbatim, customer reviews, ad hoc studies, web forms, etc.</p><p>The themes with tone (positive, negative or neutral) as well as their monthly evolution to better understand and compare customer feedback. Feel free to request a demo!</p>`
                                },
                                question: {
                                    fr: "Que permet de gérer l'analyse sémantique ?",
                                    en: "What's the scope of semantic analysis ?"
                                }
                            },
                            {
                                answer: {
                                    fr: `<p>Oui, un Customer Success Manager est votre interlocuteur dédié. Selon le niveau d'accompagnement souhaité, il vous accompagne et conseille au cours des différentes étapes de votre projet : de la mise en place aux premiers retours d'expériences en passant par un suivi régulier et pro-actif de vos besoins et indicateurs.</p><p>Si vous avez besoin d'une aide ponctuelle pour une réunion, les équipes sont là pour vous aider.</p>`,
                                    en: `<p>Yes, a Customer Success Manager will be your dedicated point of contact. Depending on your preferred level of support, they will accompany and advise you during the different stages of your project: from the initial implementation to the first feedback, through regular and proactive monitoring of your needs and indicators.</p><p>For occasional help or for a meeting, our teams are here to help you.</p>`
                                },
                                question: {
                                    fr: "Proposez-vous de l'accompagnement avec votre plateforme de feedback Management ?",
                                    en: "Do you offer support with your Feedback Management platform ?"
                                }
                            }
                        ],
                        link: {
                            fr: "Contactez-nous",
                            en: "Contact us"
                        }
                    }
                },
                about: {
                    h1: {
                        fr:"Découvrez notre entreprise et ses valeurs",
                        en:"Discover our company and our values"
                    },
                    h1_p: {
                        fr:`Depuis <time datetime="${coloradoBirthDate.value}">${age.value} années</time> de conseil et de développement, notre équipe n'a pas cessé d'implémenter des fonctionnalités innovantes et utiles pour nos clients.`,
                        en:`For <time datetime="${coloradoBirthDate.value}">${age.value} years</time> of consulting and development, our team has not stopped implementing innovative and useful features for our customers.`
                    },
                    h2_ourHistory: {
                        fr: "Notre histoire",
                        en: "Our history"
                    },
                    h3_time_colorado: {
                        fr: "2003 - Conseil - Colorado Groupe",
                        en: "2003 - Consulting - Colorado Group"
                    },
                    article_time_colorado: {
                        fr: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptas laboriosam ea nihil officia eos dolores eius quaerat. Accusamus atque maiores ut ab nam laboriosam at, excepturi quia id a repellendus nihil, tenetur voluptatem doloribus similique consequuntur, maxime distinctio enim",
                        en: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptas laboriosam ea nihil officia eos dolores eius quaerat. Accusamus atque maiores ut ab nam laboriosam at, excepturi quia id a repellendus nihil, tenetur voluptatem doloribus similique consequuntur, maxime distinctio enim"
                    },
                    h3_time_clive: {
                        fr: "2014 - Création de la plateforme Clive",
                        en: "2014 - Creation of the Clive platform"
                    },
                    article_time_clive: {
                        fr: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptas laboriosam ea nihil officia eos dolores eius quaerat. Accusamus atque maiores ut ab nam laboriosam at, excepturi quia id a repellendus nihil, tenetur voluptatem doloribus similique consequuntur, maxime distinctio enim",
                        en: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptas laboriosam ea nihil officia eos dolores eius quaerat. Accusamus atque maiores ut ab nam laboriosam at, excepturi quia id a repellendus nihil, tenetur voluptatem doloribus similique consequuntur, maxime distinctio enim"
                    },
                    h3_time_konecta: {
                        fr: "2022 - Groupe Konecta",
                        en: "2022 - Konecta Group"
                    },
                    article_time_konecta: {
                        fr: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptas laboriosam ea nihil officia eos dolores eius quaerat. Accusamus atque maiores ut ab nam laboriosam at, excepturi quia id a repellendus nihil, tenetur voluptatem doloribus similique consequuntur, maxime distinctio enim",
                        en: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum voluptas laboriosam ea nihil officia eos dolores eius quaerat. Accusamus atque maiores ut ab nam laboriosam at, excepturi quia id a repellendus nihil, tenetur voluptatem doloribus similique consequuntur, maxime distinctio enim"
                    },
                    h2_few_figures: {
                        fr: "Quelques chiffres",
                        en: "A few figures"
                    },
                    article_few_figures: {
                        fr: "Avec ConsumerLive®, collectez les avis de vos clients, enrichissez vos CRM, mesurez votre NPS, identifiez les détracteurs et réengagez vos clients simplement et rapidement.",
                        en:"With ConsumerLive®, collect your customers' opinions, enrich your CRMs, measure your NPS, identify detractors and re-engage your customers simply and quickly."
                    }
                },
                contact: {
                    h1: {
                        fr: "Contactez-nous !",
                        en: "Contact us !"
                    },
                    ph1: {
                        fr: "Vous avez une question sur notre outil ou vous désirez organiser une démo de notre solution ? Envoyez-nous un message en remplissant ce formulaire.",
                        en: "Do you have a question about our tool or would you like to organize a demo of our solution? Send us a message by filling out this form."
                    },
                    h2: {
                        fr: "A propos de ConsumerLive®...",
                        en: "About ConsumerLive®..."
                    },
                    ph2: {
                        fr: "ConsumerLive® est une solution appartenant à la société Colorado Groupe.<br><br>Visitez notre page Welcome To The Jungle pour en savoir plus sur notre entreprise et découvrir les postes à pourvoir. ",
                        en: "ConsumerLive® is a solution owned by the Colorado Group company.<br><br>Visit our Welcome To The Jungle page to learn more about our company and discover open positions."
                    },
                    form: {
                        input: {
                            name: {
                                fr: "Nom & Prénom",
                                en: "Last name & First Name",
                                placeholder: {
                                    fr: "",
                                    en: ""
                                }
                            },
                            email: {
                                fr: "Email professionnel",
                                en: "Professional email",
                                placeholder: {
                                    fr: "",
                                    en: "",
                                }
                            },
                            company: {
                                fr: "Entreprise",
                                en: "Company",
                                placeholder: {
                                    fr: "",
                                    en: ""
                                }
                            },
                            telephone: {
                                fr: "Téléphone",
                                en: "Telephone",
                                placeholder: {
                                    fr: "",
                                    en: ""
                                }
                            }
                        },
                        textarea: {
                            message: {
                                fr: "Votre message",
                                en: "Your message",
                            },
                            placeholder: {
                                fr: "Saisissez votre texte...",
                                en: ""
                            }
                        },
                        button: {
                            send: {
                                fr: "Envoyer",
                                en: "Send"
                            }
                        },
                        errors: {
                            empty: {
                                fr: "Veuillez remplir ce champ",
                                en: "Please complete this field"
                            },
                            invalidEmail: {
                                fr: "Le format de l'email est invalide",
                                en: "Email format is invalid"
                            },
                            postError: {
                                fr: "Une erreur est survenue, votre demande n'a pas pu être envoyée.",
                                en: "An error has occured, your request was not sent."
                            },
                            tooLong: {
                                fr: "Votre message est trop long",
                                en: "Your message is too long"
                            }
                        },
                        checkbox: {
                            consent: {
                                fr: "En remplissant ce formulaire, je consens au traitement de mes données personnelles, en application de la politique de traitement des données. J’ai été informé disposer d’un droit d’accès, d’opposition, d’interrogation, de limitation, de portabilité, d’effacement, de modification et de rectification des informations me concernant. Ces droits doivent être exercés en adressant un courrier au Délégué à la Protection des Données de Colorado-ConsumerLab à dpo@consumer-live.com",
                                en: "By completing this form, I consent to the processing of my personal data, in accordance with the data processing policy. I have been informed that I have a right of access, opposition, interrogation, limitation, portability, deletion, modification and rectification of information concerning me. These rights must be exercised by writing to the Data Protection Officer of Colorado-ConsumerLab at dpo@consumer-live.com"
                            }
                        },
                        successMessage: {
                            fr: "Merci, votre message a bien été envoyé.",
                            en: "Thank you, your message has been sent."
                        }
                    }
                }
            }
        }
    },
  })