/**
 * CALCULATE AGE IN YEARS FROM A START DATE
 * @param day - day of start date (1 - 31)
 * @param month - month of start date (1 - 12)
 * @param year - year of start date (YYYY)
 * @returns a number of years separating the current date and the input date
 */
function calculateAgeInYears(day: number, month: number, year: number):number {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    let calculatedAge = currentYear - year;

    if(currentMonth < month - 1) {
        calculatedAge -=1;
    }
    if(month - 1 === currentMonth && currentDay < day) {
        calculatedAge -= 1;
    }
    return calculatedAge;
}

export default {
    calculateAgeInYears
}
